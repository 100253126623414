import React, { Component } from 'react';
import liffHelper from "../utils/liffHelper";
import { CustomInput, Form, FormGroup, Input, Label } from "reactstrap";
import swal from "sweetalert2";
import CatchService from "./CatchService";
import moment from "moment";


export default class CatchTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            time: moment(new Date()).format('HH:mm'),
            select: '抓蛇',
            catchName: '無',
            phone: '',
            address: '',
            catchers: '',
            selectedArea: 'Zhongpu',
        };
        // const profile = liffHelper.getProfile()
        //     .then(profile => {
        //         this.setState({ profile });
        //     });




    }
    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    };

    handleSubmit = (event) => {
        // const profile = liffHelper.getProfile();

        CatchService.postCatchTable(this.state)
            .then(function (response) {
                console.log(response);
                swal({
                    type: 'success',
                    title: '成功送出',
                    showConfirmButton: false,
                    timer: 1000
                }).then((value) => {
                    liffHelper.closeWindow();
                });
            })
            .catch(function (error) {
                console.log(error);
            });

        event.preventDefault();
    };


    render() {
        let formGroups = (
            <Form>
                <FormGroup>
                    <Label for="time">時間</Label>
                    <Input type="time" name="time" id="time" placeholder="time placeholder"
                        value={this.state.time}
                        onChange={this.handleInputChange}
                    />
                </FormGroup>
                <FormGroup>
                    <Label for="catchSelect">Select</Label>
                    <Input type="select" name="select" id="catchSelect"
                        value={this.state.select}
                        onChange={this.handleInputChange}
                    >
                        <option value='捕蜂'>捕蜂</option>
                        <option value='抓蛇'>抓蛇</option>
                        <option value='動物救援'>動物救援</option>
                    </Input>
                </FormGroup>
                <FormGroup>
                    <Label for="address">地址</Label>
                    <Input type="text" name="address" id="address" placeholder="請輸入通報人地址"
                        value={this.state.address}
                        onChange={this.handleInputChange}
                    />
                </FormGroup>
                <FormGroup>
                    <Label for="phone">電話</Label>
                    <Input type="number" name="phone" id="phone" placeholder="請輸入電話"
                        value={this.state.phone}
                        onChange={this.handleInputChange}
                    />
                </FormGroup>
                <FormGroup>
                    <Label for="catchers">誰出動</Label>
                    <Input type="text" name="catchers" id="catchers" placeholder="請輸入派誰出去"
                        value={this.state.catchers}
                        onChange={this.handleInputChange}
                    />
                </FormGroup>
                <FormGroup
                >
                    <legend>
                        地區
                    </legend>
                    <FormGroup check>
                        <Input
                            type="radio"
                            name="selectedArea"
                            value="Zhongpu"
                            checked={this.state.selectedArea === "Zhongpu"}
                            onChange={this.handleInputChange}

                        />
                        <Label check>
                            中埔
                        </Label>
                    </FormGroup>
                    <FormGroup check>
                        <Input
                            type="radio"
                            name="selectedArea"
                            value="Puzi"
                            checked={this.state.selectedArea === "Puzi"}
                            onChange={this.handleInputChange}

                        />
                        <Label check>
                            朴子
                        </Label>
                    </FormGroup>
                    <FormGroup check>
                        <Input
                            type="radio"
                            name="selectedArea"
                            value="Minxiong"
                            checked={this.state.selectedArea === "Minxiong"}
                            onChange={this.handleInputChange}

                        />
                        <Label check>
                            民雄
                        </Label>
                    </FormGroup>
                </FormGroup>
            </Form>
        );

        return (
            <div className="page-content">
                <div className="col-lg-3" />
                <div className="col-lg-6">
                    {formGroups}
                    <hr />
                    <button type="button" className="btn btn-primary" onClick={this.handleSubmit}>送出</button>
                </div>
                <div className="col-lg-3" />
            </div>
        );
    }
}
