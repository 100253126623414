import React, { Component } from 'react';
import man from '../assets/img/man.png';
import liffHelper from '../utils/liffHelper';
import {GoogleApiWrapper, InfoWindow, Map, Marker} from 'google-maps-react';
export class MapContainer extends Component {

  constructor(props) {
    super(props);

    // this.textInput = [];
    //
    // this.setTextInputRef = (key, element) => {
    //     this.textInput[key] = element;
    // };
  }
  onInfoWindowClose(){

  }

  onMarkerClick(){

  };
  render() {
    return (
        <Map google={this.props.google} zoom={14}>

          {/*<Marker onClick={this.onMarkerClick}*/}
                  {/*name={'Current location'} />*/}

          {/*<InfoWindow onClose={this.onInfoWindowClose}>*/}
            {/*<div>*/}
              {/*<h1>{this.state.selectedPlace.name}</h1>*/}
            {/*</div>*/}
          {/*</InfoWindow>*/}
        </Map>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyBym77iKyCJo5mTggDrRCgK1I7vPC5QupA",
  language: "chinese"
})(MapContainer)


