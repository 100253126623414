import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";
import './App.css';
import Map from './pages/Map';
import Header from './pages/components/Header';
import CatchTable from "./pages/CatchTable";

class App extends Component {
  render() {
    return (
      <div className="app">
        <Router>
          <div>
            <Route exact path="/" component={CatchTable} />
            <Route path="/map" component={Map} />
          </div>
        </Router>
      </div>
    );
  }
}

export default App;
