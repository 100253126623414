import axios from 'axios';
import moment from "moment";

export default class CatchService {


    static postCatchTable(state) {

        return axios.post('/catch-service/api/v1/catch', {
            time: moment(state.time, 'HH:mm'),
            type: state.select,
            reporterName: state.catchName,
            phone: state.phone,
            locationAddress: state.address,
            catchers: state.catchers,
            selectedArea: state.selectedArea
        })

    }
}

